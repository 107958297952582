/* Dependencies */
import type { GetStaticProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';

// Services
import { httpClient } from '@services/httpClient';

// Helpers
import { generateOgImages } from '@helpers/generateOgImages/generateOgImages';

// Components
import {
  enhanceComponents,
  MapComponents,
} from '@mappers/MapComponents/MapComponents';
import { PreviewModeOverlay } from '@organisms/PreviewModeOverlay/PreviewModeOverlay';

// Models
import { Page } from '@models/Contentful.model';
import { TypeTemplatePageFields } from '@models/contentful';
import { PageContext } from '@context/PageContext';

/**
 * Home Page
 * @param props - Required page props.
 * @returns
 */
const Home: NextPage<Page> = ({
  fields: { title, seo, components },
  preview,
}) => {
  return (
    <>
      {seo && seo.fields && !seo.fields.hideFromSeo && seo.fields.title && (
        <NextSeo
          title={`MMT | ${seo.fields.title}`}
          description={seo.fields.description}
          openGraph={{
            title: seo.fields.openGraphTitle || seo.fields.title,
            description:
              seo.fields.openGraphDescription || seo.fields.description,
            images: generateOgImages(seo),
          }}
        ></NextSeo>
      )}

      <PageContext.Provider value={{ pageTitle: title }}>
        <MapComponents components={components} />
      </PageContext.Provider>

      {preview && <PreviewModeOverlay />}
    </>
  );
};

/**
 * Get the static page props.
 * @param context - Next page context.
 */
export const getStaticProps: GetStaticProps<Page> = async (context) => {
  // Retrieve The Page
  const res = await httpClient.contentful.retrievePage<TypeTemplatePageFields>(
    {
      pageContentType: 'templatePage',
      slug: '/',
      locale: 'en-GB',
    },
    !!context.preview
  );

  // Handle 404
  if (!res || !res.fields) {
    return {
      notFound: true,
    };
  }

  await enhanceComponents(res.fields.components, !!context.preview);

  // Return the page as props.
  return {
    props: {
      fields: res.fields,
      sys: res.sys,
      metadata: res.metadata,
      preview: !!context.preview,
    },
  };
};

// Exports
export default Home;
